<template>
  <div class="app-body">
    <div class="a-flex-rsbc">
      <bread-crumb></bread-crumb>
    </div>
    <div class="projectDetail">
      <div class="projectDetail-top a-flex-csbfs">
        <div class="a-flex-rfsfs">
          <span class="a-fs-16 a-fw-700">{{ detail.nickName }}</span>

          <el-tag v-if="detail.isOcr" class="a-ml-08">已实名</el-tag>
          <el-tag v-else class="a-ml-08" type="info">未实名</el-tag>
        </div>
        <div class="a-mt-16 a-flex-rsbc a-flex-wrap a-w-100">
          <div class="projectDetail-top-item">
            <div>手机号</div>
            <span>{{ detail.mobile }}</span>
          </div>
          <div class="projectDetail-top-item">
            <div>注册时间</div>
            <span>{{ detail.createTimeText }}</span>
          </div>
          <div class="projectDetail-top-item">
            <div>上次登录时间</div>
            <span>{{ detail.lastLoginTimeText }}</span>
          </div>
          <div class="projectDetail-top-item">
            <div>余额(现金/红包/赠送金)</div>
            <span>
              &yen;{{ util.numFormat(detail.balance) }} / &yen;{{
                util.numFormat(detail.activityBalance)
              }}
              / &yen;{{ util.numFormat(detail.giftBalance) }}
            </span>
          </div>
        </div>
        <div class="a-mt-32 projectDetail-top-tabs a-w-100">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="充值记录" name="czjl">
              <le-date-range
                label=""
                :min-date.sync="czjlCreateTimeStart"
                :max-date.sync="czjlCreateTimeEnd"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
              <le-pagview v-if="pageParam[0]" :page-param="pageParam[0]" @setData="czjlTableData">
                <el-table
                  :data="tableDataCZJL"
                  :highlight-current-row="true"
                  max-height="510"
                  class="zdxx-table"
                >
                  <el-table-column label="充值金额">
                    <template slot-scope="{ row }">
                      <span>&yen;{{ util.numFormat(row.changeAmount) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="充值后余额">
                    <template slot-scope="{ row }">
                      <span>&yen;{{ util.numFormat(row.afterBalance) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="balanceTypeText" label="充值类型"></el-table-column>
                  <el-table-column prop="paymentChannelIdText" label="充值渠道"></el-table-column>
                  <el-table-column prop="createTimeText" label="充值时间"></el-table-column>
                </el-table>
              </le-pagview>
            </el-tab-pane>
            <el-tab-pane label="消费记录" name="xfjl">
              <le-date-range
                ref="xfjlTime"
                label=""
                :min-date.sync="xfjlCreateTimeStart"
                :max-date.sync="xfjlCreateTimeEnd"
              />
              <le-pagview v-if="pageParam[1]" :page-param="pageParam[1]" @setData="xfjlTableData">
                <el-table
                  :data="tableDataXFJL"
                  :highlight-current-row="true"
                  max-height="510"
                  class="zdxx-table"
                >
                  <el-table-column label="订单号">
                    <template slot-scope="{ row }">
                      <span class="a-c-blue a-cursor-p" @click="godingdan(row.orderId)">
                        {{ row.orderId }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="时长">
                    <template slot-scope="{ row }">
                      <span>{{ row.minutesActual }}分钟</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="现金/红包/赠送金使用情况">
                    <template slot-scope="{ row }">
                      <span>
                        &yen;{{ util.numFormat(row.balanceUse) }} / &yen;{{
                          util.numFormat(row.activityBalanceUse)
                        }}
                        / &yen;{{ util.numFormat(row.giftBalanceUse) }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="消费金额">
                    <template slot-scope="{ row }">
                      <span>&yen;{{ util.numFormat(row.amount) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="payTypeText" label="支付方式"></el-table-column>
                  <el-table-column prop="createTimeText" label="消费时间"></el-table-column>
                </el-table>
              </le-pagview>
            </el-tab-pane>
            <el-tab-pane label="提现记录" name="txjl">
              <el-table
                :data="tableDataTXJL"
                :highlight-current-row="true"
                max-height="510"
                class="zdxx-table"
              >
                <el-table-column prop="createTimeText" label="提现时间"></el-table-column>
                <el-table-column label="提现金额">
                  <template slot-scope="{ row }">
                    <span>&yen;{{ util.numFormat(row.amount) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="提现状态">
                  <template slot-scope="{ row }">
                    <span>{{ row.isSuccess ? "成功" : "失败" }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="提现者ID">
                  <template slot-scope="{ row }">
                    <span>{{ row.type ? row.companyId : row.userId }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="提现ID">
                  <template slot-scope="{ row }">
                    <span>{{ row.type ? row.withdrawId : row.balanceId }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="mchAppid" label="appid"></el-table-column>
                <el-table-column prop="mchid" label="微信支付分配的商户号"></el-table-column>
                <el-table-column prop="errMessage" label="错误描述"></el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="充电卡管理" name="cdkgl">
              <el-table
                :data="tableDataCDKGL"
                :highlight-current-row="true"
                max-height="510"
                class="zdxx-table"
              >
                <el-table-column prop="cardNo" label="卡号"></el-table-column>
                <el-table-column label="当前余额">
                  <template slot-scope="{ row }">
                    <span>&yen;{{ util.numFormat(row.balance) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="充电次数">
                  <template slot-scope="{ row }">
                    <span>{{ row.chargingNum }}次</span>
                  </template>
                </el-table-column>
                <el-table-column label="单次最大充电时长">
                  <template slot-scope="{ row }">
                    <span>{{ row.maxChargingMinutes }}分钟</span>
                  </template>
                </el-table-column>
                <el-table-column label="绑定时间">
                  <template slot-scope="{ row }">
                    <span>{{ row.bindTime ? util.easyformatDate(row.bindTime) : "--" }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="状态">
                  <template slot-scope="{ row }">
                    <span>{{ row.status == 1 ? "暂停使用" : "正常" }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" class="a-flex-rcc">
                  <template slot-scope="{ row }">
                    <el-tooltip class="item" effect="dark" content="解绑" placement="top">
                      <span @click="handlerUnbind(row.id)">
                        <img src="../../assets/icon/userMGT-unbind.png" class="a-wh-16" />
                      </span>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import util from "../../../src/utils/util";
export default {
  name: "UserMGTDetail",

  data() {
    return {
      util: util,
      activeName: "czjl",
      id: "",
      czjlCreateTimeStart: "",
      czjlCreateTimeEnd: "",
      xfjlCreateTimeStart: "",
      xfjlCreateTimeEnd: "",
      detail: {},
      pageParam: [],
      tableDataCZJL: [],
      tableDataXFJL: [],
      tableDataTXJL: [],
      tableDataCDKGL: [],
    };
  },
  watch: {
    czjlCreateTimeStart() {
      this.pageParam[0].params.createTimeStart = this.czjlCreateTimeStart;
      this.pageParam[0].params.createTimeEnd = this.czjlCreateTimeEnd;
      this.pageParam[0].freshCtrl++;
    },
    xfjlCreateTimeStart() {
      this.pageParam[1].params.createTimeStart = this.xfjlCreateTimeStart;
      this.pageParam[1].params.createTimeEnd = this.xfjlCreateTimeEnd;
      this.pageParam[1].freshCtrl++;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getTableData();
    this.getDetail();
    this.txjlTableData();
    this.cdkglTableData();
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },
  methods: {
    czjlTableData(data) {
      this.tableDataCZJL = data;
    },
    xfjlTableData(data) {
      this.tableDataXFJL = data;
    },

    getDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getUserManagerDetail,
          params: {
            userId: parseInt(this.id),
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.detail = res.result.data;
          }
        });
    },
    handleClick() {},
    getTableData() {
      this.pageParam = [
        {
          url: this.$Config.apiUrl.getUserChargeBalanceInfoList,
          method: "post",
          params: {
            userId: this.id,
            createTimeStart: this.czjlCreateTimeStart,
            createTimeEnd: this.czjlCreateTimeEnd,
          },
          freshCtrl: 1,
        },
        {
          url: this.$Config.apiUrl.getUserConsumeList,
          method: "post",
          params: {
            userId: this.id,
            createTimeStart: this.xfjlCreateTimeStart,
            createTimeEnd: this.xfjlCreateTimeEnd,
          },
          freshCtrl: 1,
        },
      ];
    },
    //获取提现记录
    txjlTableData() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getTransfersList,
          params: {
            userId: parseInt(this.id),
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.tableDataTXJL = res.result.data;
          } else {
            this.$message.error(res.result.msg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    //获取充电卡管理列表
    cdkglTableData() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.userCardListPC,
          params: {
            userId: parseInt(this.id),
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.tableDataCDKGL = res.result.data;
          } else {
            this.$message.error(res.result.msg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    //解绑充电卡
    handlerUnbind() {},
    //跳转到订单详情
    godingdan(id) {
      this.$router.push({
        path: "/order/order-detail",
        query: {
          orderId: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.projectDetail {
  font-size: 14px;
  height: 100%;
  overflow-y: auto;

  > div {
    border-radius: 4px;
  }

  &-box {
    border-radius: 4px;
    background: #fff;
  }

  &-top {
    background: #fff;
    padding: 24px;
    padding-bottom: 64px;
    position: relative;
    padding-bottom: 0;

    &-item {
      display: flex;
      flex-direction: row;
      width: 33.3%;
      margin-top: 16px;
      font-size: 14px;
      line-height: 22px;

      > div {
        max-width: 116px;
        color: #797979;
      }

      span {
        margin-left: 16px;
      }
    }

    &-tabs {
      position: absolute;
      top: 132px;
      left: 0;

      .tjxx {
        &-echarts {
          &-title {
            width: 100%;
            padding: 16px 25px;
            border-bottom: 1px solid #ebf0f5;
            font-size: 16px;
          }

          &-box {
            padding: 15px 24px 10px;
          }
        }
      }

      .fcbl {
        padding: 24px;
        background: #fff;
        border-radius: 4px;

        &-table {
          margin-top: 16px;
          width: 100%;
          border: 1px solid #dadde0;
          padding: 0 16px 12px;

          > div {
            width: 100%;

            &:nth-of-type(1) {
              border: none;
              padding: 24px 0;
            }

            &:nth-of-type(2) {
              background: #f5f7fa;
            }

            padding: 12px 0;
            border-bottom: 1px solid #fafbfc;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;

            > * {
              width: 50%;
              text-align: center;
            }
          }
        }
      }

      .htxx {
        background: #fff;
        border-radius: 4px;

        > div {
          width: 33.33%;
          margin-bottom: 16px;

          a {
            text-decoration: none;
            color: #007aff;
            margin-left: 8px;
          }
        }
      }
    }
  }

  /deep/ .el-tabs__item {
    // color: #007aff;
    font-size: 14px;
  }

  /deep/ .el-tabs__item.is-active {
    color: #007aff;
    font-weight: bold;
  }

  /deep/ .el-tabs__nav-wrap::after {
    background: none !important;
  }

  /deep/ .el-tabs__header {
    padding: 15px 0 0;
    padding-left: 24px;
    //margin: 0;
    background: #fff;
  }

  .zdxx {
    background: #fff;
    padding: 24px;

    &-table {
      // margin-top: -24px;
      background: #fff;
      width: 100%;
    }
  }
}

/deep/ .el-date-editor--daterange {
  margin: 0;
  margin-top: -12px;
  margin-bottom: -18px;
}

.zdxx-table {
  padding: 24px;
  background: #fff;
}

/deep/ .projectDetail-top-tabs {
  margin-top: 30px;
}

/deep/ .el-table thead {
  color: #606366 !important;
}

/deep/ .el-table {
  color: #303133;
}
</style>
